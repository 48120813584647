<template>
  <div class="row justify-content-center container--situation-audit">
    <div class="col-sm-12">
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-0 sub-card"
        header-class="px-2 pt-2 py-1"
      >
        <div slot="header">
          <div class="row ml-0 mr-0">
            <b-col sm="12" md="4" class="pl-2 pr-2">
              <div>
                <h2 class="section--heading text-uppercase">SITUATION AUDIT</h2>
              </div>
              <h5 class="section--heading-note mb-0">Where do we stand?</h5>
            </b-col>
            <b-col sm="12" md="8" class="px-2 ext-icon-container">
              <div>
                <h2 class="section--heading">
                  Opportunity Assessment
                  <i
                    class="icon-info icons small-line-icon"
                    v-b-modal.so-info
                  ></i>
                </h2>
                <h5 class="section--heading-note mb-0">
                  Strengths & Opportunities
                </h5>
              </div>
              <b-modal
                size="lg"
                id="so-info"
                class="advisor-info-modal"
                title="Opportunity Assessment"
                ok-only
              >
                <div v-if="isSelectedCreditUnion">
                  <p>
                    {{ siteName }} provides analytics on a financial
                    institution’s performance and offering. The performance
                    analyses cover profitability, growth and risk management.
                    The offering assessment includes retail services and
                    business services.
                  </p>
                  <p>
                    Comparison to peers generates exceptions that are classified
                    on a five (5) point classification system. This
                    classification allows performance areas to be identified as
                    relative Strengths or Opportunities.
                  </p>
                  <p>
                    Additionally the institutions known vendor-product
                    relationships are detailed under Vendors & Products.
                  </p>
                </div>
                <div v-else>
                  <p>
                    {{ siteName }} provides analytics on a financial
                    institution’s performance and offering. The performance
                    analyses cover profitability, growth and risk management.
                    The offering assessment includes retail services and
                    business services.
                  </p>
                  <p>
                    Comparison to peers generates exceptions that are classified
                    on a five (5) point classification system. This
                    classification allows performance areas to be identified as
                    relative Strengths or Opportunities.
                  </p>
                  <p>
                    Additionally the institutions known vendor-product
                    relationships are detailed under Vendors & Products.
                  </p>
                </div>
              </b-modal>
              <div class="d-flex">
                <div
                  class="advisor-btn mr-4"
                  @click="updateSetId"
                  title="Merger Restated"
                  v-if="mergerEnabled"
                >
                  <svg class="icon">
                    <use
                      xlink:href="#merger"
                      :class="{ active: setId === 2 }"
                    ></use>
                  </svg>
                </div>
                <div class="advisor-btn" @click="spanUpdated(3)" title="Yearly">
                  <svg class="icon">
                    <use
                      xlink:href="#yearly"
                      :class="{ active: spanId === 3 }"
                    ></use>
                  </svg>
                </div>
                <div
                  class="advisor-btn"
                  @click="spanUpdated(1)"
                  title="Quarterly"
                >
                  <svg class="icon">
                    <use
                      xlink:href="#quarterly"
                      :class="{ active: spanId === 1 }"
                    ></use>
                  </svg>
                </div>
                <div
                  class="advisor-btn"
                  @click="spanUpdated(2)"
                  title="Trailing 12"
                >
                  <svg class="icon">
                    <use
                      xlink:href="#trailing-12"
                      :class="{ active: spanId === 2 }"
                    ></use>
                  </svg>
                </div>
              </div>
            </b-col>
          </div>
        </div>
        <v-wait for="fetchSituationAudit">
          <template slot="waiting">
            <div class="row">
              <b-col sm="12" md="4" class="pl-3 pr-2">
                <div
                  class="sub-card-left rounded show-bank-details"
                  style="height: 19rem;"
                >
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-heading />
                    <content-placeholders-img class="chart-loader-container" />
                  </content-placeholders>
                </div>
              </b-col>
              <b-col sm="12" md="8" class="pl-2 pr-3">
                <div class="sub-card-right rounded">
                  <div class="show-offerings" style="height: 19rem;">
                    <div class="row offerings">
                      <div class="col-md-6 offerings-col">
                        <div class="header row">
                          <div class="col-md-12">
                            <content-placeholders
                              :rounded="true"
                              class="loader"
                            >
                              <content-placeholders-heading />
                            </content-placeholders>
                          </div>
                        </div>
                        <div class="offerings-content">
                          <content-placeholders :rounded="true" class="loader">
                            <content-placeholders-text :lines="6" />
                          </content-placeholders>
                        </div>
                      </div>
                      <div class="col-md-6 offerings-col">
                        <div class="header row">
                          <div class="col-md-12">
                            <content-placeholders
                              :rounded="true"
                              class="loader"
                            >
                              <content-placeholders-heading />
                            </content-placeholders>
                          </div>
                        </div>
                        <div class="offerings-content">
                          <content-placeholders :rounded="true" class="loader">
                            <content-placeholders-text :lines="6" />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
          </template>
          <div class="row">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div
                class="sub-card-left rounded show-bank-details chart-container"
              >
                <div class="header row">
                  <div class="col-md-12">
                    <h5 class="sub-heading">
                      Primary Theme
                      <i
                        class="icon-info icons small-line-icon"
                        v-b-modal.sa-chart-info
                      ></i>
                    </h5>
                    <p class="theme-name mb-2">{{ chartData.theme_name }}</p>
                  </div>
                </div>
                <b-modal
                  size="lg"
                  id="sa-chart-info"
                  class="advisor-info-modal"
                  title="Primary Theme"
                  ok-only
                >
                  <div v-if="isSelectedCreditUnion">
                    <p>
                      {{ siteName }} provides analytics on a financial
                      institution’s performance and offering. The performance
                      analyses cover profitability, growth and risk management.
                      The offering assessment includes consumer services,
                      commercial services and service channels.
                    </p>
                    <p>
                      Comparison to peers generates exceptions that are
                      classified on a five (5) point classification system. This
                      classification allows performance areas to be identified
                      as Strengths or Opportunities. Every performance area is
                      mapped to a performance theme (ex. Personnel Costs or Core
                      Funding). Based on the costs associated with the
                      performance exception, the institution’s unique set of
                      opportunities can be summed to identify a higher priority
                      Primary Theme. The institution’s percentage of primary
                      theme exception costs to total exceptions cost is
                      displayed within the theme graphic. Any strengths or
                      opportunities mapped to that primary theme also will be
                      highlighted in blue.
                    </p>
                    <p>
                      {{ siteName }} maps performance to fifteen (15)
                      performance themes including Asset Utilization,
                      Capitalization, Core Funding, Deposit Pricing, Digital
                      Engagement, Fee Generation, Investment Portfolio,
                      Liquidity Position, Loan Growth, Loan Pricing, Other
                      Overhead, Personnel Costs, Problem Assets, Consumer
                      Offering, and Commercial Offering. Unique Primary Themes
                      are produced for the Profit, Growth and Risk analyses.
                    </p>
                  </div>
                  <div v-else>
                    <p>
                      {{ siteName }} provides analytics on a financial
                      institution’s performance and offering. The performance
                      analyses cover profitability, growth and risk management.
                      The offering assessment includes consumer services,
                      commercial services and service channels.
                    </p>
                    <p>
                      Comparison to peers generates exceptions that are
                      classified on a five (5) point classification system. This
                      classification allows performance areas to be identified
                      as Strengths or Opportunities. Every performance area is
                      mapped to a performance theme (ex. Personnel Costs or Core
                      Funding). Based on the costs associated with the
                      performance exception, the institution’s unique set of
                      opportunities can be summed to identify a higher priority
                      Primary Theme. The institution’s percentage of primary
                      theme exception costs to total exceptions cost is
                      displayed within the theme graphic. Any strengths or
                      opportunities mapped to that primary theme also will be
                      highlighted in blue.
                    </p>
                    <p>
                      {{ siteName }} maps performance to fifteen (15)
                      performance themes including Asset Utilization,
                      Capitalization, Core Funding, Deposit Pricing, Digital
                      Engagement, Fee Generation, Investment Portfolio,
                      Liquidity Position, Loan Growth, Loan Pricing, Other
                      Overhead, Personnel Costs, Problem Assets, Consumer
                      Offering, and Commercial Offering. Unique Primary Themes
                      are produced for the Profit, Growth and Risk analyses.
                    </p>
                  </div>
                </b-modal>
                <SituationAuditChart
                  :chartData="getChartData"
                ></SituationAuditChart>
              </div>
            </b-col>

            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="sub-card-right rounded chart-container">
                <div class="show-offerings">
                  <div class="row offerings">
                    <div class="col-md-6 offerings-col">
                      <div class="header row">
                        <div class="col-md-7">
                          <h5 class="sub-heading left-header">Strengths</h5>
                        </div>
                      </div>
                      <div class="offerings-content strength-container p-2">
                        <div
                          v-for="(strength, index) in strengths"
                          :key="index"
                        >
                          <div
                            :class="getThemeclass(strength)"
                            class="strength-metric"
                            @click="redirectToReport(strength)"
                          >
                            {{ getLinkName(strength) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 offerings-col">
                      <div class="header row">
                        <h5 class="sub-heading right-header">Opportunities</h5>
                      </div>
                      <div class="offerings-content strength-container p-2">
                        <div
                          v-for="(opportunity, index) in opportunities"
                          :key="index"
                        >
                          <div
                            :class="getThemeclass(opportunity)"
                            class="opportunity-metric"
                            @click="redirectToReport(opportunity)"
                          >
                            {{ getLinkName(opportunity) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </div>
        </v-wait>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SituationAuditChart from './SituationAuditChart'

export default {
  name: 'SituationAudit',
  components: {
    SituationAuditChart
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    reportId: {
      type: Number,
      required: true
    },
    treeId: {
      type: Number,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchSituationAuditData()
  },
  data() {
    return {
      strengths: {},
      opportunities: {},
      chartData: {}
    }
  },
  computed: {
    ...mapState({
      sidebarApps: state => state.Apps.sidebarApps
    }),
    ...mapGetters('Advisor', [
      'selectedInstitution',
      'spanId',
      'setId',
      'menuData',
      'routeMenuMapper',
      'isSelectedCreditUnion'
    ]),
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return 'FI Navigator'
    },
    getChartData() {
      let performanceExceptionsRaw = this.chartData.perf_exception || 0
      let chartActiveFillValue = Math.round(performanceExceptionsRaw * 100.0)
      let chartDefaultFillValue = 100 - chartActiveFillValue
      return [chartActiveFillValue, chartDefaultFillValue]
    },
    mergerEnabled() {
      return this.selectedInstitution.fi_type === 'bank'
    }
  },
  methods: {
    ...mapMutations('Advisor', ['setSpanId', 'toggleSet', 'setPrimaryTheme']),
    redirectToReport(option) {
      if (this.productId == 4) {
        this.$router.push({
          path: '/analytics/advisor/offering/retail_services'
        })
      } else {
        this.$http
          .get('/api/metrics/parent_metric', {
            handleErrors: true,
            params: {
              report_id: this.reportId,
              product_id: this.productId,
              metric_id: option.metric_id
            }
          })
          .then(res => {
            let name = res.data.menu.menu_display
              .toLowerCase()
              .replace(/\s|-/g, '_')
            let mapperRoute = this.routeMenuMapper[name] || name

            this.$router.push({
              path: `/analytics/advisor/${this.reportType.toLowerCase()}/${mapperRoute}`,
              query: { metricId: option.metric_id }
            })
          })
      }
    },
    getThemeclass(obj) {
      return this.chartData.theme_id == obj.theme_id
        ? 'anchor-active'
        : 'anchor-default'
    },
    getLinkName(obj) {
      return this.productId == 4 ? obj.name : obj.strength_opportunity_name
    },
    spanUpdated(spanId) {
      this.setSpanId(spanId)
      this.fetchSituationAuditData()
    },
    updateSetId() {
      this.toggleSet()
      this.fetchSituationAuditData()
    },
    fetchSituationAuditData() {
      this.$wait.start('fetchSituationAudit')
      this.$http
        .get('api/reports/situation_audit', {
          handleErrors: true,
          params: {
            report_id: this.reportId,
            product_id: this.productId,
            span_id: this.spanId,
            set_id: this.setId
          }
        })
        .then(res => {
          this.chartData = res.data.chart_data
          this.opportunities = res.data.opportunities
          this.strengths = res.data.strengths
          this.setPrimaryTheme(this.chartData.theme_name)
        })
        .then(() => this.$wait.end('fetchSituationAudit'))
    },
    metricUrl(metric) {
      let navUrl
      let advisorApp = this.sidebarApps.find(app => app.name === 'FIN Advisor')
      advisorApp.children.some(nav => {
        if (nav.children) {
          let subNav = nav.children.find(
            navItem => navItem.name === metric.menu_display
          )
          if (subNav) {
            navUrl = subNav.url
            return true
          }
        }
      })
      return navUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.ext-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.anchor-active,
.anchor-active:hover,
.anchor-default:hover {
  color: #3396b7;
}
.anchor-default,
.anchor-default:visited {
  color: #343434;
}
.left-header {
  margin-left: 0.2rem;
  margin-bottom: 0px;
}
.right-header {
  margin-left: 1rem;
  margin-bottom: 0px;
}

.sub-heading {
  font-size: 1rem;
}

.theme-name {
  margin-top: 0.5rem;
  font-size: 14px;
}

.show-bank-details,
.sub-card-right {
  &.chart-container {
    height: 19rem;
  }
}

.offerings-content {
  &.strength-container {
    height: 15rem;
  }
}

.chart-loader-container {
  height: 11.7rem;
}

.strength-metric,
.opportunity-metric {
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 0.125rem 0;
  font-size: 0.8125rem;
}

@media print {
  .container--situation-audit {
    page-break-after: always;

    .sub-card-right {
      &.chart-container {
        height: auto;
        max-height: 55rem;

        .show-offerings {
          height: auto;
        }

        .offerings-content {
          &.strength-container {
            height: auto;
            max-height: 51rem;
          }
        }
      }
    }
  }
}
</style>
