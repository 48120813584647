<template>
  <b-table
    striped
    :items="flattenedRows"
    :fields="columns"
    current-page="1"
    :per-page="flattenedRows.length"
    class="job-search-table px-2"
    :show-empty="true"
    empty-text="No records found."
  >
    <template v-slot:cell(rssd_id)="data">
      <span
        @click="toggleDisplay(data.item)"
        :style="treeSpacing(data.item)"
        class="cursor-pointer text-nowrap"
      >
        <i :class="showToggleIcon(data.item)"></i>
        {{ data.item.rssd_id }}
      </span>
    </template>
    <template v-slot:cell(state)="data">
      <span>
        {{ data.item.state === 0 ? '' : data.item.state }}
      </span>
    </template>
    <template v-slot:cell(parent_id)="data">
      <span>
        {{ data.item.parent_id === 0 ? '' : data.item.parent_id }}
      </span>
    </template>
    <template v-slot:cell(assets)="data">
      <span class="rightAlign">
        {{ parseFloat(data.item.assets) | formatValue('$#,###') }}
      </span>
    </template>
  </b-table>
</template>
<script>
export default {
  name: 'TreeTable',
  data() {
    return {
      flattenedRows: []
    }
  },
  props: {
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    rows: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  created() {
    this.flattenedRows = this.flatten(this.rows)
  },
  methods: {
    flatten(rows) {
      return rows.reduce((flattenedRows, row) => {
        return flattenedRows.concat([
          row,
          ...(row && row._showChildren ? this.flatten(row._children) : [])
        ])
      }, [])
    },
    toggleDisplay(row) {
      row._showChildren = !row._showChildren
      this.flattenedRows = this.flatten(this.rows)
    },
    treeSpacing(row) {
      return { 'padding-left': row._parentCount * 1.5 + 'rem' }
    },
    showToggleIcon(row) {
      return row._children.length > 0
        ? row._showChildren
          ? 'fa fa-minus-square'
          : 'fa fa-plus-square'
        : ''
    }
  }
}
</script>
