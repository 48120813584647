<template>
  <div>
    <PDFHeader
      :pageName="pageName"
      :peerGroup="selectedPeerGroup"
      :institutionName="selectedInstitution.name || ''"
      :logoUrl="s3LogoUrl"
      class="pb-2"
      v-if="showPDFHeader"
    ></PDFHeader>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <b-card
          class="std-border mb-3"
          body-class="px-3 pb-3 pt-0 sub-card"
          header-class="px-2 pt-2 pb-0"
        >
          <div slot="header">
            <div class="row ml-0 mr-0">
              <b-col sm="12" md="4" class="pl-3 pr-2">
                <div class="row">
                  <b-col md="12" lg="12" class="pl-2">
                    <div>
                      <h2 class="section--heading text-uppercase">
                        ACTION PLAN
                        <i
                          class="icon-info icons small-line-icon"
                          v-b-modal.ap-info
                        ></i>
                      </h2>
                    </div>
                    <h5 class="section--heading-note">What now?</h5>
                    <b-modal
                      size="lg"
                      id="ap-info"
                      class="advisor-info-modal"
                      title="Action Plan"
                      ok-only
                    >
                      <div v-if="isSelectedCreditUnion">
                        <div v-if="metricActionPlan">
                          <p>
                            The Action Plan is designed to help the financial
                            institution determine “what’s next” in terms of
                            resourcing to augment performance. Opportunities are
                            more abundant at macro-performance metrics (ex. ROE)
                            than at lower levels of the decision tree as they
                            cumulate or roll-up from lower level metrics. For
                            every opportunity, {{ siteName }} provides a unique
                            set of actions to improve performance. Clicking on
                            these actions filters the solutions displayed to the
                            right.
                          </p>
                          <p>
                            Additionally the user can filter the solutions
                            displayed by a desired next step that includes
                            analyze, inform or deploy implementable solutions.
                          </p>
                        </div>
                        <div v-else>
                          <p>
                            The Action Plan is designed to help the financial
                            institution determine “what now” in terms of
                            resourcing to augment performance. For every
                            opportunity, {{ siteName }} suggests a unique set of
                            actions to improve performance. The drop down box
                            allows the user to filter by performance themes
                            within the Executive Brief. Clicking on these
                            actions filters the solutions displayed to the
                            right.
                          </p>
                          <p>
                            Additionally the user can filter the solutions
                            displayed by a desired next step that includes
                            analyze, inform or deploy implementable solutions.
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="metricActionPlan">
                          <p>
                            The Action Plan is designed to help the financial
                            institution determine “what’s next” in terms of
                            resourcing to augment performance. Opportunities are
                            more abundant at macro-performance metrics (ex. ROE)
                            than at lower levels of the decision tree as they
                            cumulate or roll-up from lower level metrics. For
                            every opportunity, {{ siteName }} provides a unique
                            set of actions to improve performance. Clicking on
                            these actions filters the solutions displayed to the
                            right.
                          </p>
                          <p>
                            Additionally the user can filter the solutions
                            displayed by a desired next step that includes
                            analyze, inform or deploy implementable solutions.
                          </p>
                        </div>
                        <div v-else>
                          <p>
                            The Action Plan is designed to help the financial
                            institution determine “what now” in terms of
                            resourcing to augment performance. For every
                            opportunity, {{ siteName }} suggests a unique set of
                            actions to improve performance. The drop down box
                            allows the user to filter by performance themes
                            within the Executive Brief. Clicking on these
                            actions filters the solutions displayed to the
                            right.
                          </p>
                          <p>
                            Additionally the user can filter the solutions
                            displayed by a desired next step that includes
                            analyze, inform or deploy implementable solutions.
                          </p>
                        </div>
                      </div>
                    </b-modal>
                  </b-col>
                </div>
              </b-col>
              <b-col sm="12" md="8" class="pl-2 pr-3">
                <div class="row">
                  <b-col md="7">
                    <div>
                      <h2 class="section--heading">Deploy</h2>
                    </div>
                    <h5 class="section--heading-note">
                      Solutions for Implementation
                    </h5>
                  </b-col>
                  <b-col
                    md="5"
                    class="d-flex justify-content-end align-items-end mb-2"
                  >
                    <span
                      class="action-plan-btn"
                      title="Solution Implementation"
                    >
                      <svg class="icon">
                        <use xlink:href="#bulb" class="active"></use>
                      </svg>
                    </span>
                  </b-col>
                </div>
              </b-col>
            </div>
          </div>
          <div class="row">
            <b-col sm="12" md="4" class="pl-3 pr-2">
              <div class="sub-card-left rounded">
                <div>
                  <v-wait for="fetchingActionPlan">
                    <template slot="waiting">
                      <content-placeholders :rounded="true" class="loader">
                        <content-placeholders-heading />
                        <content-placeholders-text
                          :lines="13"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                    <div class="action-plan-content">
                      <div v-if="!metricActionPlan">
                        <Multiselect
                          track-by="theme_id"
                          label="theme"
                          v-model="selectedTheme"
                          :allowEmpty="false"
                          :showLabels="false"
                          :options="themes"
                          class="simple-select analytics-select"
                          @select="updateOpportunities"
                        ></Multiselect>
                      </div>

                      <h5 class="note">Opportunity</h5>
                      <div
                        :class="[
                          metricActionPlan ? 'with-metric' : 'without-metric',
                          'opportunity'
                        ]"
                      >
                        <ul class="opportunity-ul mb-0">
                          <li
                            v-for="(opportunity,
                            index) in selectedTheme.opportunities"
                            class="opportunity-li"
                            :key="index"
                            :class="{
                              active:
                                selectedOpportunity.opportunity_id ===
                                opportunity.opportunity_id
                            }"
                            @click="setOpportunity(opportunity)"
                          >
                            {{ opportunity.opportunity }}
                          </li>
                        </ul>
                      </div>
                      <h5 class="note">Actions</h5>
                      <div
                        :class="[
                          metricActionPlan ? 'with-metric' : 'without-metric',
                          'actions'
                        ]"
                      >
                        <ul class="action-ul mb-0" v-if="selectedOpportunity">
                          <li
                            v-for="(action,
                            index) in selectedOpportunity.actions"
                            :key="index"
                            :class="{
                              active:
                                selectedAction.action_id === action.action_id
                            }"
                            class="action-li"
                            @click="setAction(action)"
                          >
                            {{ action.action }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </v-wait>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="8" class="pl-2 pr-3">
              <div class="sub-card-right rounded" id="deploy-solutions">
                <div>
                  <v-wait for="fetchingActionPlan">
                    <template slot="waiting">
                      <content-placeholders
                        :rounded="true"
                        class="deploy-loader justify-content-center"
                      >
                        <div class="row m-2">
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                        </div>
                        <div class="row m-2">
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                        </div>
                        <div class="row m-2">
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                          <b-col sm="6" md="4" class="pl-3 pr-3">
                            <content-placeholders-img
                              class="deploy-holder"
                            ></content-placeholders-img>
                          </b-col>
                        </div>
                      </content-placeholders>
                    </template>
                    <div class="solution-content">
                      <ul class="row solution-ul mb-0">
                        <li
                          v-for="(solution, index) in actionSolutions"
                          :key="index"
                          class="col-md-4 solution-li"
                        >
                          <div class="solution-box">
                            <div class="box-content">
                              <div
                                class="vendor-count text-right pr-2"
                                title="Number of vendor solutions"
                                v-if="hasVendorCount(solution.vendor_count)"
                              >
                                {{ solution.vendor_count }}
                              </div>
                              <div
                                class="solution-name px-2 pb-3"
                                title="Solution"
                              >
                                {{ solution.solution }}

                                <div
                                  class="peer-value text-center font-weight-normal"
                                  title="% Peers Offering"
                                  v-if="
                                    solution.peer_value &&
                                      parseFloat(solution.peer_value) !== 0
                                  "
                                >
                                  {{ solution.peer_value | percent }}
                                </div>
                              </div>
                            </div>
                            <div class="solution-footer">
                              <div class="footer-partition">
                                <button
                                  class="button-link solution-modal-btn"
                                  type="button"
                                  @click="showPartnerProducts(solution)"
                                >
                                  <span
                                    class="action-plan-btn"
                                    title="View Solutions"
                                  >
                                    <svg class="icon">
                                      <use xlink:href="#bulb"></use>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                              <div class="footer-partition text-right">
                                <button
                                  class="button-link solution-modal-btn"
                                  type="button"
                                  @click="showPartnerProducts(solution)"
                                >
                                  <span
                                    class="action-plan-btn"
                                    title="View Solutions"
                                  >
                                    <svg class="icon">
                                      <use xlink:href="#dots-more"></use>
                                    </svg>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </v-wait>
                </div>
              </div>
            </b-col>
          </div>
        </b-card>
      </div>
      <PartnerProductsModal :solution="selectedSolution"></PartnerProductsModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PDFHeader from './PDFHeader'
import Multiselect from 'vue-multiselect'
import PartnerProductsModal from '../components/PartnerProductsModal'

export default {
  name: 'ActionPlan',
  components: {
    PDFHeader,
    Multiselect,
    PartnerProductsModal
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    bankServiceId: {
      required: true
    },
    metricActionPlan: {
      type: Boolean,
      required: true,
      default: false
    },
    pageName: {
      type: String,
      default: '-'
    },
    showPDFHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    treeId: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      themes: [],
      selectedTheme: {},
      selectedOpportunity: {},
      selectedAction: {},
      actionSolutions: [],
      selectedSolution: {}
    }
  },
  computed: {
    ...mapGetters('Advisor', [
      'spanId',
      'setId',
      'reportId',
      's3LogoUrl',
      'selectedMetricId',
      'primaryTheme',
      'isSelectedCreditUnion',
      'selectedInstitution',
      'selectedPeerGroup'
    ]),

    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return 'FI Navigator'
      // return this.partnerSite ? 'Performance Intelligence' : 'FI Navigator'
    },
    apiUrl() {
      return this.metricActionPlan
        ? `/api/metrics/${this.selectedMetricId}/action_plan`
        : '/api/summary/action_plan'
    },
    optionsHeight() {
      return this.metricActionPlan ? 9 : 8
    }
  },
  methods: {
    setOpportunity(opportunity) {
      this.selectedOpportunity = opportunity
      this.selectedAction = this.selectedOpportunity.actions[0]
    },
    setAction(action) {
      this.selectedAction = action
    },
    updateOpportunities(theme) {
      this.selectedOpportunity = theme.opportunities[0]
      this.selectedAction = this.selectedOpportunity.actions[0]
    },
    showPartnerProducts(solution) {
      this.selectedSolution = solution
      this.$bvModal.show('partnerProductModal')
    },
    hasVendorCount(count) {
      return count && count > 0
    },
    fetchActionPlanData() {
      this.$wait.start('fetchingActionPlan')
      this.$http
        .get(this.apiUrl, {
          params: {
            set_id: this.setId,
            tree_id: this.treeId,
            span_id: this.spanId,
            report_id: this.reportId,
            product_id: this.productId,
            bank_service_id: this.bankServiceId
          },
          handleErrors: true
        })
        .then(res => {
          this.themes =
            res.data.themes ||
            Object.assign({}, this.themes, [
              { opportunities: res.data.opportunities }
            ])
          if (Array.isArray(this.themes)) {
            let chosenTheme = this.themes.findIndex(
              obj => obj.theme === this.primaryTheme
            )
            this.selectedTheme = this.themes[Math.max(chosenTheme, 0)]
          } else {
            this.selectedTheme = this.themes[0]
          }
          this.selectedOpportunity = this.selectedTheme.opportunities[0]
          this.selectedAction = this.selectedOpportunity.actions[0] || {}
        })
        .catch(() => {})
        .then(() => {
          this.$wait.end('fetchingActionPlan')
        })
    }
  },
  watch: {
    selectedMetricId: {
      handler: function(newVal) {
        this.fetchActionPlanData()
      },
      immediate: true
    },

    selectedAction() {
      let solutions = this.selectedAction.solutions || []
      this.actionSolutions = solutions.sort((a, b) =>
        a.fi_count > b.fi_count ? 1 : -1
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$ext-more-img: url('../../../../static/img/ext-more.png');

$bg_white: white;
$gray_border: #f7f5f7;
$proxima_regular: Proxima-Nova-Regular, Ubuntu, 'Helvetica Neue', Helvetica,
  Arial, sans-serif;

.sub-card {
  background-color: #f0f3f5;

  .sub-card-left,
  .sub-card-right {
    border: solid 1px lightgray;
    background-color: white;
    height: 25.5rem;
  }
}

.action-plan-content {
  padding: 1rem 0.75rem;
  border-radius: 0.25rem;

  .note {
    text-transform: uppercase;
    font-size: 0.875rem;
    margin-top: 0;
    margin-bottom: 0.75rem;
  }

  .opportunity {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .opportunity,
  .actions {
    overflow-y: auto;
    padding: 0.5rem 0;
    border: 2px solid $gray_border;
    border-radius: 0.25rem;

    &.with-metric {
      height: 9rem;
    }

    &.without-metric {
      height: 8rem;
    }
  }

  .opportunity-ul,
  .action-ul {
    padding-left: 0;
    list-style: none;
    font-size: 0.75rem;
  }

  .opportunity-li,
  .action-li {
    cursor: pointer;
    color: #6c6d70;
    padding: 0.125rem 0.5rem;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    &.active {
      color: #5ab2ef;
    }
  }
}

.solution-content {
  height: 25.3rem;
  background-color: $bg_white;
  border: 1px solid $gray_border;
  font-size: 0.895rem;
  padding: 1rem 0.75rem;

  overflow-y: auto;
  border-radius: 0.25rem;

  .no-solutions {
    font-size: 1.75rem;
    color: #c1c1c1;
    font-family: $proxima_regular;
    text-align: center;
    margin-top: 3rem;
  }

  .solution-ul {
    list-style: none;
    padding: 0;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

  .solution-li {
    padding: 0 0.5rem 0.5rem;

    .solution-box {
      background-color: #5ab2ef;
      border-radius: 0.25rem;
      color: $bg_white;
      border: 1px solid $gray_border;
      height: 9rem;
      position: relative;

      .box-content {
        height: 6.3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .solution-name {
      text-align: center;
      text-transform: uppercase;
      font-family: $proxima_regular;
    }

    .footer-partition {
      width: 50%;
      float: left;
    }

    .solution-footer {
      clear: both;
      background-color: #51a0d7;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.375rem 0.5rem 0.125rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .vendor-count,
  .peer-value {
    font-size: 10px;
    cursor: default;
    font-weight: bolder;
  }
}

.deploy-holder {
  margin: 0.1rem;
  height: 120px;
  width: 180px;
}

.deploy-loader {
  margin: 1rem;
  overflow: hidden;
  height: 21rem;
}

@media print {
  .metric-report-action-plan {
    .action-plan-content {
      .opportunity,
      .actions {
        height: 11.4rem;
      }
    }

    .sub-card-right,
    .sub-card-left {
      height: 29.5rem;
    }

    .sub-card-right {
      .solution-content {
        height: 29.3rem;
      }
    }
  }

  .offering-action-plan {
    .action-plan-content {
      .opportunity,
      .actions {
        height: 15.7rem;
      }
    }

    .sub-card-right,
    .sub-card-left {
      height: 41.5rem;
    }

    .sub-card-right {
      .solution-content {
        height: 41.3rem;
      }
    }
  }
}
</style>
