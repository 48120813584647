<template>
  <b-modal
    size="lg"
    id="st-peers-info"
    class="advisor-info-modal"
    :title="siteName + ' Peer Groups'"
    ok-only
  >
    <div v-if="isSelectedCreditUnion">
      <h5><b>Strategic Peers</b></h5>
      <p>
        {{ siteName }} has developed a strategic classification system to ensure
        the matching of a strategic peer group that shares a similar lending,
        funding and off- balance sheet focus. A credit union’s operating
        strategy, as reflected by its balance sheet and revenue composition
        heavily influences its route towards higher performance. Comparison to
        strategic comparables generates performance exceptions (or conclusions)
        that, when addressed, are much more likely to accurately guide resource
        allocation for performance improvement.
      </p>
      <p>
        The base strategic classification divides financial institutions into
        four (4) lending strategies, three (3) funding strategies and two (2)
        off-balance sheet revenue levels. These combinations result in 24
        strategic classes.
      </p>
      <p>
        The four lending strategies (and their abbreviated labels) are secured
        personal lending (AUTO), residential real estate lending (HOME),
        unsecured personal lending (UPER) and diverse lending focus (DIVR). The
        AUTO loan focus group is for institutions where secured personal lending
        exceeds the 60th percentile and exceeds both residential real estate and
        unsecured personal lending for the institution. The HOME loan focus
        group is for institutions where residential real estate lending exceeds
        the 75th percentile and exceeds both secured personal and unsecured
        personal lending for the institution. The UPER loan focus group is for
        institutions where unsecured lending exceeds the 66th percentile and
        exceeds both secured personal and residential real estate lending for
        the institution. The DIVR loan focus group is for credit unions with
        more diverse portfolios that would not meet the thresholds for the other
        three groups.
      </p>
      <p>
        The three funding strategies (and their abbreviated labels) are low
        share balance (LBAL), moderate share balance (MBAL) and high share
        balance (HBAL). The classifications utilize the institutions average
        share balance with those under the 33rd percentile in the low balance
        (LBAL) group and those between the 33rd and 60th percentile in the
        moderate balance (MBAL) group. The high balance (HBAL) group’s average
        balances exceeds the 60th percentile. Lower share balances are highly
        correlated with lower cost of deposits and higher fee income levels –
        but are also associated with higher personnel and other expenses.
      </p>
      <p>
        The two off-balance sheet classifications are moderate (MOD) and high
        (HGH). The classification examines the credit union’s level of other
        operating income relative to average earning assets. Other operating
        income for the institution includes real estate loan secondary market
        income, interchange income, and other recurring non-service charge
        income. As such the metric is a proxy for expanded and/or off-balance
        sheet income sources. Institutions below the 40th percentile in other
        operating income to average earning assets are designated as moderate
        (MOD) with the remainder designated as high (HGH).
      </p>
      <div class="strategy-img">
        <img src="../../../../static/img/cu-strategy.png" />
      </div>
      <div class="pt-1">
        <h5><b>Market Peers</b></h5>
        <p>
          {{ siteName }} has developed a geographic peer selection system to
          construct a market peer group for the subject institution based on its
          county footprint. The system identifies all counties from which the
          institution has branch locations plus any contiguous (or bordering)
          counties which are aggregated to define the institution’s footprint.
          Then the system filters all institutions within that footprint to
          identify those that have more than 50 percent of their branches within
          the footprint. The top 50 institutions exceeding that criteria become
          the subject institution’s market peer group.
        </p>
        <h5><b>Asset Peers</b></h5>
        <p>
          {{ siteName }}’s asset peers divide financial institutions into six
          (6) asset classes based on reported total assets in the most recent
          period.
        </p>
      </div>
    </div>
    <div v-else>
      <h5><b>Strategic Peers</b></h5>
      <p>
        {{ siteName }} has developed a strategic classification system to ensure
        the matching of a strategic peer group that shares a similar lending,
        funding and off-balance sheet focus. A financial institution’s operating
        strategy, as reflected by its balance sheet and revenue composition
        heavily influences its route towards high performance. Comparison to
        strategic comparables generates performance exceptions (or conclusions)
        that, when addressed, are much more likely to impact performance.
      </p>
      <p>
        The base strategic classification divides financial institutions into
        five (5) lending strategies, three (3) funding strategies and three (3)
        off-balance sheet revenue levels. These combinations result in 45
        strategic classes. An additional two (2) classifications cover the
        atypical strategies of credit card institutions and bankers’ banks.
      </p>
      <div class="strategy-img">
        <img src="../../../../static/img/bank-strategy.png" />
      </div>
      <div class="pt-1">
        <h5><b>Market Peers</b></h5>
        <p>
          {{ siteName }} has developed a geographic peer selection system to
          construct a market peer group for the subject institution based on its
          county footprint. The system identifies all counties from which the
          institution has branch locations plus any contiguous (or bordering)
          counties which are aggregated to define the institution’s footprint.
          Then the system filters all institutions within that footprint to
          identify those that generate more than 50 percent of their deposits
          from within the footprint. The top 50 institutions exceeding that
          criteria become the subject institution’s market peer group.
        </p>
        <h5><b>Asset Peers</b></h5>
        <p>
          {{ siteName }}’s asset peers divide financial institutions into six
          (6) asset classes based on reported total assets in the most recent
          period.
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PeerGroupInfoModal',
  computed: {
    ...mapGetters('Advisor', ['isSelectedCreditUnion']),
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return 'FI Navigator'
    }
  }
}
</script>
